<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
          class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>Products</h3>
          <div class="justify-content-between">
            <div
              class="ps-btn btn-sm mr-2"
              data-toggle="modal"
              data-target="#uploadProductsModal"
            >
              <i class="icon-upload"></i> Upload Products
            </div>

            <!-- Modal -->
            <div
              class="modal fade"
              id="uploadProductsModal"
              tabindex="-1"
              aria-labelledby="uploadProductsModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="uploadProductsModalLabel">
                      Upload Products
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body ls-modal-body">
                    <h4>Upload Excel file with product details</h4>
                    <p>Select the file here and click on upload</p>
                    <form>
                      <input type="file" @change="handleFileUpload($event)" />
                      <button
                        class="btn btn-primary"
                        @click.prevent="uploadProducts()"
                      >
                        Upload
                      </button>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-success"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'merchant_create_products' }"
              class="ps-btn btn-sm"
              ><i class="icon-plus"></i> Create Product</router-link
            >
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
              <tr>
                <th>Code</th>
                <th>Product</th>
                <th>Category</th>
                <th>Price</th>
                <th>In Stock</th>
                <th>Available On Login</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in products" :key="product.id">
                <td>{{ product.code }}</td>
                <td>
                  <a href="#">{{ product.name }}</a>
                </td>
                <td>
                  {{ product.category == null ? "" : product.category.name }}
                </td>
                <td class="text-right">{{ product.price }}</td>
                <td>{{ product.in_stock }}</td>
                <td>
                  <span
                    class="text-success"
                    v-if="product.is_available_on_login"
                  >
                    <i class="icon-check"></i>
                  </span>
                  <span v-else class="text-danger">
                    <i class="icon-cross"></i>
                  </span>
                </td>
                <td>
                  <span
                    v-if="product.status.name === 'pending'"
                    class="text-warning"
                    >{{ product.status.label }}
                  </span>
                  <span
                    v-if="product.status.name === 'active'"
                    class="text-success"
                    >{{ product.status.label }}
                  </span>
                  <span
                    v-if="product.status.name === 'deleted'"
                    class="text-danger"
                    >{{ product.status.label }}
                  </span>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'merchant_edit_products',
                      params: { id: product.id },
                    }"
                  >
                    <i class="icon-pen"></i>
                  </router-link>
                  <!--                <a class="ml-3" href="#" @click="deleteProduct(product.id)"><i class="fa fa-trash" /></a>-->
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="(!meta || meta.total === 0) && !isLoading">
                <td colspan="7" class="text-center m-4 text-danger p-4">
                  No products available
                </td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="7" class="text-center m-4 text-danger">
                  Loading products...
                </td>
              </tr>
              <tr v-else>
                <td class="text-right" colspan="7">
                  <ul class="pagination text-right">
                    <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                      <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                    </li>
                    <li class="active">
                      <a href="#">Page {{ pageNumber }}</a>
                    </li>
                    <!--                      <li><a href="#">3</a></li>-->
                    <li v-on:click="goToNextPage()" v-if="hasNextPage">
                      <a href="#"
                        >Next Page<i class="icon-chevron-right"></i
                      ></a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "List",
  data() {
    return {
      products: [],
      shop: null,
      meta: null,
      isLoading: false,
      isDeleting: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      uploadFile: "",
      count: 0,
    };
  },
  mounted() {
    this.getShop();
  },
  methods: {
    handleFileUpload($event) {
      this.uploadFile = event.target.files[0];
    },
    uploadProducts() {
      console.log("Upload FIle : " + JSON.stringify(this.uploadFile));
      let formData = new FormData();
      formData.append("file", this.uploadFile);
      formData.append("shop_id", "123");

      axios
        .post("/products/import/list", formData)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success == "true") {
            this.$toasted.show("File was uploaded successfully");
          } else {
            this.$toasted.error("Failed to upload : " + response.data.message);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          this.$toasted.error(
            "Failed to upload. An error occurred, please try again"
          );
        });
    },

    getShop() {
      axios.get("shops/view").then((response) => {
        this.shop = response.data;
        this.getProducts();
      });
    },

    getProducts() {
      this.isLoading = true;
      //let url = "products";
      let url = "products/shop/" + this.shop.id;
      if (this.url) {
        url = this.url;
      }

      axios
        .get(url)
        .then((response) => {
          this.products = response.data.data;

          // myProducts.forEach((product) => {
          //   alert("Shop ID " + this.shop.id);
          //   if (product.shop.id == this.shop.id) {
          //     this.products.push(product);
          //   }
          // });
          this.meta = response.data.meta;
          this.links = response.data.links;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    deleteProduct(id) {
      if (confirm("Are you sure you want to delete this product")) {
        this.isDeleting = true;
        axios
          .delete("products/" + id)
          .then((response) => {
            this.getProducts();
            this.isDeleting = false;
          })
          .catch((error) => {
            this.isDeleting = false;
          });
      }
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },

    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        this.getProducts();
      }
      this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        this.getProducts();
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>

<style scoped></style>
